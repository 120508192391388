 <template>
  <PageMetadata />
  <PageCanonical v-if="prods.total > 0" :canonical="{ totalRecords: prods.total }" />
  <section>
    <flash-alert class="my-2" v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <loading-overlay :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="container ps-0">
      <h1 class="pb-0 mb-4 px-0">
        <template v-if="urlCategory.title">{{ urlCategory.title }}</template>
        <template v-else>{{ getMetaDetails.page_h1 }}</template>
      </h1>
      <div class="content_below_heading" v-if="urlCategory.id && !!urlCategory.desc" v-html="urlCategory.desc"></div>
      <div class="content_below_heading" v-else-if="!!getMetaDetails.description" v-html="getMetaDetails.description"></div>
      <div v-for="(disclaimer, Key) in disclaimers" :key="Key" v-html="disclaimer.message"></div>
      <section v-if="!isLoggedIn || availableDates.length > 0">
        <div v-if="displayProducts">
          <div class="my-50">
            <div class="row">
              <div class="col-12 offset-lg-2 col-lg-6">
                <div class="row align-items-center text-center text-lg-start" v-if="isLoggedIn && availableDates.length > 0">
                  <h5 id="delivery-date-label" class="col-lg pe-lg-0 col-12 bolder-size mb-3 mb-lg-0">{{ this.deliveryDateLabel }}</h5>
                  <div class="col-lg-auto col-12">
                    <div class="input-group d-md-inline-flex align-items-center delivery-date-row">
                      <div class="calender_container mx-auto">
                        <delivery-date-picker v-model:dateProp="filters.tmpDate" @deliveryDateChanged="processDateChange"
                          :minDate="minDate" :maxDate="maxDate" :disabledDates="disabledDates" pageKey="shpDeliveryDate" />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="deliveryDateDescription" class="text-center text-lg-start">{{ this.deliveryDateDescription }}</div>
                <cutoffs v-if="availableDates.length" :specificProg="false" :cutOffs="cutOffs[filters.date]" :eligibleProgs="displayCutoffForPrograms" />
              </div>
            </div>
          </div>
          <div class="row mb-4 align-items-center">
            <div class="offset-lg-2 col-lg-3 mb-0 text-center text-lg-start">
              <b><label class="mb-0 p-0">Total Products:
                  <span id="total_products">{{ prods.total }}</span></label></b>
            </div>
            <div class="col-lg-7 mt-2 mt-lg-0">
              <div class="row gx-3 justify-content-between">
                <div class="mt-0 col-5 col-sm-auto">
                  <div class="
                      delivery-address
                      d-flex
                      align-items-center
                      justify-content-between
                      mb-2 mb-sm-0
                    ">
                    <label class="mb-0 p-0" for=""><b>Show:</b></label>
                    <div class="d-flex flex-grow-1">
                      <div class="
                          d-flex
                          justify-content-around
                          align-items-center
                          p-0
                        ">
                        <select class="form-select" v-model.number="filters.perPage">
                          <option value="16">16</option>
                          <option value="48">48</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-0 col-7 col-sm-auto">
                  <div class="
                      delivery-address
                      d-flex
                      align-items-center
                      justify-content-between
                      mb-2 mb-sm-0
                    ">
                    <label class="mb-0 p-0" for=""><b>Sort By:</b></label>
                    <div class="d-flex flex-grow-1">
                      <select class="form-select" v-model="filters.sortBy">
                        <option value="Name-ASC">Name (A - Z)</option>
                        <option value="Name-DESC">Name (Z - A)</option>
                        <option v-if="isLoggedIn" value="Price-ASC">
                          Price/Unit Asc
                        </option>
                        <option v-if="isLoggedIn" value="Price-DESC">
                          Price/Unit Desc
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="
                    col-12 col-sm-auto
                    mt-1 mt-sm-0
                    align-self-center
                    text-center
                    ms-md-2
                  ">
                  <div id="grid-list-btn-container" class="d-inline-flex">
                    <Popper :arrow="true" :hover="true" content="List">
                      <button type="button" id="list-view" @click="changeView('list')" class="btn btn-default">
                        <i class="fa-solid fa-table-list"></i>
                      </button>
                    </Popper>

                    <Popper :arrow="true" :hover="true" content="Grid">
                      <button type="button" id="grid-view" @click="changeView('grid')" class="btn btn-default">
                        <i class="fa-solid fa-table-cells-large"></i>
                      </button>
                    </Popper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="displayProducts" class="row">
          <div class="col-lg-2">
            <div class="sidebar">
              <div class="mb-4 text-center text-md-start">
                <input type="button" id="reset-btn" class="btn btn-theme" value="Reset" @click="resetFilters" />
              </div>

              <div class="mb-4 input-group">
                <input type="text" class="form-control width-0" aria-label="Search" aria-describedby="button-addon2"
                  v-model="filters.search" @keyup.enter="applySearch" />
                <button class="btn btn-theme" type="button" id="button-addon2" @click="applySearch">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
              </div>

              <div v-for="(accordian, accordKey) in filterSection" :class="['mb-1', accordKey == currentAccordion ? 'open' : '', {'d-none': (accordKey === 'units'), }, ]" :key="accordKey">
                <div class="sidebar-heading" v-on:click="expand(accordKey)">
                  <p>{{ accordian.label }} <i class="fa-solid fa-plus"></i></p>
                </div>
                <template v-for="(filterss, filterGroup) in accordian" :key="filterGroup">
                  <template v-if="filterGroup != 'label'">
                    <div class="filter-container pt-0 px-2 px-md-0" :class="{ 'mh-100': filterGroup == 'filter_color_combo' }">
                      <ul class="filters" :class="{'bubble_color_wrapper my-1': filterGroup == 'filter_color_combo', }">
                        <li :class="{bubble_color: filterGroup == 'filter_color_combo',}" v-for="(filter, key) in filterss" :key="key">
                          <a v-if="'image' in filter && filter.image != '' && filter.image != null" href="#" @click.prevent="
                              setFilterCriteria({
                                group: filterGroup,
                                value: filter.id,
                                label: filter.label,
                              })
                            ">
                            <Popper :arrow="true" :hover="true" :content="filter.label">
                              <span :class="[
                                  'filter-color',
                                  'filter-image',
                                  filter.border,
                                  filterGroup in filters.criteria &&
                                  filter.id in filters.criteria[filterGroup]
                                    ? filter.checkmark
                                    : '',
                                ]" :style="{
                                  height: '22px',
                                  width: '22px',
                                  display: 'block',
                                  'border-radius': '50%',
                                  'background-image':
                                    `${imagePath}` + filter.image,
                                }"><img :class="[
                                    'filter-color',
                                    filter.border,
                                    filterGroup in filters.criteria &&
                                    filter.id in filters.criteria[filterGroup]
                                      ? filter.checkmark
                                      : '',
                                  ]" :src="`${imagePath}` + filter.image" /></span>
                            </Popper>
                          </a>
                          <a v-else-if="'hex' in filter && filter.hex != '' && filter.hex != null" href="#" @click.prevent="
                              setFilterCriteria({
                                group: filterGroup,
                                value: filter.id,
                                label: filter.label,
                              })
                            ">
                            <Popper :arrow="true" :hover="true" :content="filter.label">
                              <span :class="[
                                  'filter-color',
                                  filter.border,
                                  filterGroup in filters.criteria &&
                                  filter.id in filters.criteria[filterGroup]
                                    ? filter.checkmark
                                    : '',
                                ]" class="" :style="{
                                  height: '22px',
                                  width: '22px',
                                  display: 'block',
                                  'border-radius': '50%',
                                  'background-color': filter.hex,
                                }"></span>
                            </Popper>
                          </a>
                          <a v-else-if="'href' in filter" :href="filter.href">
                            {{ filter.label }}
                          </a>
                          <template v-else>
                            <input type="checkbox" :value="filter.id" :checked="
                                filterGroup in filters.criteria &&
                                filter.id in filters.criteria[filterGroup]
                              " @change="
                                setFilterCriteria(
                                  {
                                    group: filterGroup,
                                    value: filter.id,
                                    label: filter.label,
                                  },
                                  $event
                                )
                              " />{{ filter.label }}
                          </template>
                        </li>
                        <template v-if="accordKey === 'programs'">
                          <li>
                            <router-link :to="{ name: 'DutchDirectBoxlot' }" class="dutch-direct-boxlots-links">Dutch
                              Direct Boxlots</router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'HardGood' }" class="hardgoods-links">Hardgoods</router-link>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="col-lg-10" v-if="prods.total > 0" id="">
            <pagination-row :recordsCount="prods.total" :perPage="filters.perPage" :currPage="filters.pageNumb"
              @pageChange="changePage" />
            <div id="selected-filters-list-top">
              <template v-for="(criteria, group) in filters.criteria" :key="group">
                <template v-if="group !== 'filter_program'">
                  <span v-for="(val, key) in criteria" :key="key">
                    {{ val }}
                    <a href="#" @click.prevent="removeFilterCriteria(group, key)">x</a>
                    &nbsp;
                  </span>
                </template>
              </template>
            </div>
            <div class="product-grid-list-wrapper cart-page-table-wrapper" v-if="view == 'grid'" id="view-grid">
              <div class="row">
                <product-card v-for="(product, index) in prods.data" :key="index" :prodId="product" :product="product"
                  :deliveryDate="filters.date" @addToWl_pc="addToWlAfterMath"></product-card>
              </div>
            </div>
            <div class="product-wrapper" id="view-list" v-else-if="view == 'list'">
              <div v-if="isLoggedIn" class="card product-card border-0">
                <div class="card-header bg-grey d-none d-lg-block text-white">
                  <div class="row mx-0">
                    <div class="col-auto product-img-wrap"></div>
                    <div class="col product-name pl-0">Product Name</div>
                    <div class="col col-lg-auto lot-size">Available</div>
                    <div class="col col-lg-auto price">Price/Unit</div>
                    <div class="col col-lg-auto lot-price">Price</div>
                    <div class="col-auto action"></div>
                  </div>
                </div>
                <div v-for="(product, index) in prods.data" :key="index" class="card-body position-relative">
                  <div class="row no-gutters">
                  <template v-if="this.filters.date != ''">
                    <router-link class="p-0 w-auto m-0" :to="`/${product.seo_url}?date=${this.filters.date}`">
                      <Popper v-if="product.highlight_name" :arrow="true" :hover="true"
                        :content="`${product.highlight_name}`">
                        <div class="ribbon2">
                          <span
                            :style="`border-color: ${product.highlight_hex_code}; background: ${product.highlight_hex_code}; background: linear-gradient(${ product.highlight_hex_code } 0%, ${ product.highlight_hex_code } 100%);`">{{
                            product.highlight_name }}</span>
                        </div>
                      </Popper>
                    </router-link>
                  </template>
                  <template v-else>
                    <router-link class="p-0 w-auto m-0" :to="`/${product.seo_url}`">
                      <Popper v-if="product.highlight_name" :arrow="true" :hover="true"
                        :content="`${product.highlight_name}`">
                        <div class="ribbon2">
                          <span
                            :style="`border-color: ${product.highlight_hex_code}; background: ${product.highlight_hex_code}; background: linear-gradient(${ product.highlight_hex_code } 0%, ${ product.highlight_hex_code } 100%);`">{{
                            product.highlight_name }}</span>
                        </div>
                      </Popper>
                    </router-link>
                  </template>

                    <div class="
                        col-auto
                        product-img-wrap
                        position-relative
                        no-overflow
                      ">
                      <template v-if="this.filters.date != ''">
                      <router-link :to="`/${product.seo_url}?date=${filters.date}`">
                        <img v-if="product.image" :src="`${imagePath}` + product.image" class="m-0 zoom"
                          :alt="product.name" />
                        <img v-else :src="
                            `${imagePath}` +
                            getDefaultImage(product.product_type_id)
                          " class="m-0" :alt="product.name" />
                      </router-link>
                      </template>
                      <template v-else>
                      <router-link :to="`/${product.seo_url}`">
                        <img v-if="product.image" :src="`${imagePath}` + product.image" class="m-0 zoom"
                          :alt="product.name" />
                        <img v-else :src="
                            `${imagePath}` +
                            getDefaultImage(product.product_type_id)
                          " class="m-0" :alt="product.name" />
                      </router-link>
                      </template>

                      <add-to-wishlist v-if="isLoggedIn" :prod="product" class="fr-wish"></add-to-wishlist>
                    </div>
                    <div class="col product-name  pl-0">
                      <div class="tool-tip">
                        <Popper class="d-inline ml-1 breakdown-popper" v-if="
                            product.breakdown != null &&
                            product.breakdown.length > 2
                          " arrow="true" hover="true">
                          <template #content>
                            <div v-if="product.programtype == 'prb' || product.programtype == 'lal'" 
                              v-html="getBreakdownForPulls(product.breakdown, product.box_unit_singular)"></div>
                            <div v-else v-html="getBreakdown(product.breakdown, product.box_unit_singular)"></div>
                          </template>
                          <span class="star-color"><i class="fa-solid fa-star text-warning" aria-hidden="true"></i>&nbsp;</span>
                        </Popper>
                        <span class="d-inline-block highlight-description" v-if="product.highlight_description">
                        <Popper :arrow="true" :hover="true" :content="product.highlight_description" style="margin:0; border: none;">
                          <i class="fa-solid fa-circle-info info-icon"></i>
                        </Popper>
                        </span>
                        <template v-if="this.filters.date != ''">
                        <router-link :to="`/${product.seo_url}?date=${filters.date}` ">{{ product.name }}</router-link> 
                        </template>
                        <template v-else>
                        <router-link :to="`/${product.seo_url}`">{{ product.name }}</router-link>
                        </template>
                        <span v-if="product.country_id > 0">&nbsp;</span>
                        <span class="us_img_icon d-inline-block" v-if="product.country_id > 0">
                          <Popper :arrow="true" :hover="true" :content="product.country_name">
                            <img :src="`${imagePath}` + product.country_flag" />
                          </Popper>
                        </span>
                        <span v-if="product.state_id > 0">&nbsp;</span>
                        <span class="us_img_icon d-inline-block" v-if="product.state_id > 0">
                          <Popper :arrow="true" :hover="true" :content="product.state_name">
                            <img :src="`${imagePath}` + product.state_flag" />
                          </Popper>
                        </span>
                        <Popper v-if="product.special" :arrow="true" :hover="true" :content="`${product.special}% Off`">
                          <div class="ms-2 badge bg-peach">
                            <span>{{ product.special }}% Off</span>
                          </div>
                        </Popper>
                        <br />
                        <span><i>{{ product.farm_name }}<template v-if="product.is_k2k"> - </template></i></span>
                      </div>
                    </div>
                    <div class="w-100 d-block d-lg-none"></div>
                    <div class="col col-lg-auto lot-size">
                      <div>
                        <label class="d-block d-lg-none">Available</label>
                        <span v-if="product.programtype == 'mdb' || product.programtype == 'lal' || product.programtype == 'fdb'">{{ product.qty }}</span>
                        {{
                        product.qty > 1
                        ? product.box_name_plural
                        : product.box_name_singular
                        }}
                        <br />
                        <span v-if="
                            product.box_name_singular != product.box_unit_singular
                          ">({{ product.unit_count }}
                          {{
                          product.unit_count > 1
                          ? product.box_unit_plural
                          : product.box_unit_singular
                          }})</span>
                      </div>
                    </div>
                    <div class="col col-lg-auto price">
                      <div>
                        <label class="d-block d-lg-none">Price/Unit</label>
                        <strong>
                          ${{ $filters.numberFormat(product.price_per_stem) }} /
                          {{ product.box_unit_singular }}
                        </strong>
                      </div>
                    </div>
                    <div class="col col-lg-auto lot-price">
                      <div>
                        <label class="d-block d-lg-none">Price</label>
                        ${{ $filters.numberFormat(product.price_per_unit) }} /
                        {{ product.box_name_singular }}
                      </div>
                    </div>

                    <div class="action text-right" :class="[isLoggedIn ? 'col-auto' : 'col-12']">
                      <div>
                        <label class="d-none d-md-block d-lg-none">&nbsp;</label>
                        <div v-if="isLoggedIn" class="d-flex align-items-center justify-content-center">
                          <add-to-cart :max="product.qty" :prod="product" :min="product.min_qty" :step="product.inc_qty" :deliveryDate="filters.date" />
                        </div>
                        <div v-else class="d-flex align-items-center justify-content-center">
                          <a class="btn btn-theme ml-1" :href="'/login'">LOGIN</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="card product-card border-0">
              <div class="card-header bg-grey d-none d-lg-block text-white">
                <div class="row mx-0">
                  <div class="col-auto product-img-wrap"></div>
                  <div class="col product-name pl-0">Product Name</div>
                  <div class="col-auto action"></div>
                </div>
              </div>

                <div v-for="(product, index) in prods.data" :key="index" class="card-body">
                  <div class="row no-gutters">
                    <div class="col-auto product-img-wrap position-relative">
                      <router-link :to="`/${product.seo_url}`">
                        <img v-if="product.image" :src="`${imagePath}` + product.image" class="" :alt="product.name" />
                        <img v-else :src="
                            `${imagePath}` +
                            getDefaultImage(product.product_type_id)
                          " class="" :alt="product.name" />
                      </router-link>
                      <span></span>
                    </div>
                    <div class="col product-name pl-0">
                      <div>
                        <router-link :to="`/${product.seo_url}`">{{
                          product.name
                          }}</router-link>
                      </div>
                    </div>
                    <div class="w-100 d-block d-lg-none"></div>
                    <div class="action text-right" :class="[isLoggedIn ? 'col-auto' : 'col-12']">
                      <div>
                        <label class="d-none d-md-block d-lg-none">&nbsp;</label>
                        <div class="
                            text-center
                            align-items-center
                            justify-content-center
                          ">
                          <a class="btn btn-theme ml-1" :href="'/login'">LOGIN</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-10">
              <pagination-row :recordsCount="prods.total" :perPage="filters.perPage" :currPage="filters.pageNumb"
                @pageChange="changePage" />
            </div>
          </div>
          <div v-if="filters.criteria && prods.total < 1" class="col-lg-10">
            <div id="selected-filters-list-top">
              <template v-for="(criteria, group) in filters.criteria" :key="group">
                <template v-if="group !== 'filter_program'">
                  <span v-for="(val, key) in criteria" :key="key">
                    {{ val }}
                    <a href="#" @click.prevent="removeFilterCriteria(group, key)">x</a>
                    &nbsp;
                  </span>
                </template>
              </template>
            </div>
            <p class="text">
              The filters you have selected do not match any available products.
              Remove or select a different filter combination to view our
              selection of fresh blooms.
            </p>
          </div>

          <div id="page_content_below" class="mt-10 content-wrapper" v-if="!!getMetaDetails.description_below_dynamic"
            v-html="getMetaDetails.description_below_dynamic"></div>

          <recently-viewed />
        </div>
      </section>
    </div>
  </section>
 </template>

<script>
import _ from "lodash";
import { mapMutations } from "vuex";
import {
  PaginationRow,
  ProductCard,
  FlashAlert,
  AddToCart,
  DeliveryDatePicker,
  AddToWishlist,
  RecentlyViewed,
  Cutoffs,
  PageMetadata,
  PageCanonical,
} from "@/components";
import axios from "axios";
import { misc, cutoff } from "@/mixins";
import { mapGetters } from "vuex";
import Popper from "vue3-popper";

export default {
  name: "CategoryPage",
  mixins: [misc, cutoff],
  components: {
    PaginationRow,
    ProductCard,
    FlashAlert,
    AddToCart,
    DeliveryDatePicker,
    AddToWishlist,
    Popper,
    RecentlyViewed,
    Cutoffs,
    PageMetadata,
    PageCanonical,
  },
  mounted() {
    this.urlCategory.slug = this.$route.params.pageSlug;

    this.fetchProgramDetails();
    this.categoryDetails().then(() => {
      this.updateBc();
    });

    localStorage.setItem("currentPage", this.urlCategory.slug);
    this.fetchProductsTypes();
    let site_settings = JSON.parse(localStorage.getItem("site_settings"));
    this.mayesh_pull_product_type_id = site_settings.mayesh_pull_product_type_id;
    this.us_message = site_settings.us_flag_message;
    this.fetchMetaDetails();

    if ("perPage" in this.$route.query || "s_perPage" in this.$route.query) {
      this.triggerWatch = false;
      this.filters.perPage = parseInt(this.$route.query.perPage ?? this.$route.query.s_perPage, 10);
    }

    if ("sortBy" in this.$route.query || "s_sortBy" in this.$route.query) {
      this.filters.sortBy =
        this.$route.query.sortBy ?? this.$route.query.s_sortBy;
    }

    if ("pageNumb" in this.$route.query || "s_pageNumb" in this.$route.query) {
      this.filters.pageNumb = parseInt(
        this.$route.query.pageNumb ?? this.$route.query.s_pageNumb,
        10
      );
      setTimeout(() => {
        this.pageLoaded = true;
      }, 1000);
    }

    if ("criteria" in this.$route.query || "s_criteria" in this.$route.query) {
      this.filters.criteria = JSON.parse(
        this.$route.query.criteriaInt ?? this.$route.query.s_criteriaInt
      );
    }

    if ("search" in this.$route.query || "s_search" in this.$route.query) {
      this.filters.search = decodeURI(
        this.$route.query.search
          ? this.$route.query.search
          : this.$route.query.s_search != undefined
          ? this.$route.query.s_search
          : ""
      );
      if (this.filters.search == undefined) this.filters.search = "";
    }

    if (this.getShopDeliveryDate) {
      this.triggerWatch = false;
      this.filters.tmpDate = this.getShopDeliveryDate;
      this.processDateChange();
    } else if (
      ("date" in this.$route.query || "s_date" in this.$route.query) && (!("filter_bt" in this.$route.query) || this.$route.query.filter_bt !== "1")) {
      this.filters.tmpDate = this.$route.query.date ?? this.$route.query.s_date;
      this.processDateChange();
    } else if (
      ("date" in this.$route.query || "s_date" in this.$route.query) && (("filter_bt" in this.$route.query) || this.$route.query.filter_bt === "1")) {
      this.filters.tmpDate = this.$route.query.date ?? this.$route.query.s_date;
      this.processDateChange();
    }

    this.$nextTick(() => {
      this.triggerWatch = true;
    });
    if (this.isLoggedIn) {
      this.fetchDates();
    } else {
      this.fetchFilters();
    }

    this.emitter.on("a2c", (payload) => {
      this.addToCartAfterMath(payload);
    });

    this.emitter.on("deletedProduct", () => {
      this.fetchProducts();
    });
    this.emitter.on("cartExpiredReload", () => {
      this.cartExpired();
    });

    if (this.availableDates.length >= 1) {
      setTimeout(() => {
        this.viewProductListToGA()  
      }, 1000);
    }
  },
  unmounted() {
    this.emitter.off("a2c");
    this.emitter.off("deletedProduct");
    this.emitter.off("cartExpiredReload");
  },
  data() {
    return {
      us_message: "US Product",
      productTypes: [],
      currentAccordion: "",
      availableDates: [],
      disabledDates: [],
      mixDate: '',
      maxDate: '',
      isLoading: false,
      fullPage: true,
      loader: "bars",
      allProgramsSettings: [],
      displayProducts: true,
      filterSection: {},
      pageStaticContent: {},
      prods: {
        total: 0,
        data: [],
      },
      filters: {
        perPage: this.getPerPage(),
        sortBy: this.getSortBy(),
        pageNumb: 1,
        tmpDate: "",
        date: "",
        criteria: {},
        search: "",
      },
      view: localStorage.getItem("viewType")
        ? localStorage.getItem("viewType")
        : "list",
      triggerWatch: true,
      pageLoaded: false,
      disclaimers: [],
      mayesh_pull_product_type_id: 3,
      urlProgram: {
        id: 0,
        desc: "",
        title: "",
        display_cutoff: false,
      },
      urlCategory: {
        id: 0,
        desc: "",
        title: "",
        meta_title: "",
        meta_description: "",
        meta_keywords: "",
        slug: "",
        display_cutoff: false,
      },
      cutOffs: {},
      displayCutoffForPrograms: [],
      deliveryDateLabel: '',
      deliveryDateDescription: '',
    };
  },
  computed: {
    ...mapGetters(["getShopDeliveryDate", "isSalesRep", "isESales"]),

    apiBody() {
      const criteria = {};
      for (let group in this.filters.criteria) {
        criteria[group] = Object.keys(this.filters.criteria[group]);
      }

      return {
        perPage: this.filters.perPage,
        sortBy: this.filters.sortBy,
        pageNumb: this.filters.pageNumb,
        date: this.filters.date,
        is_sales_rep: this.isSalesRep,
        is_e_sales: this.isESales,
        criteria,
        criteriaInt: this.filters.criteria,
        search: this.filters.search,
      };
    },
  },
  methods: {
    ...mapMutations(["updateDynamicBreadcrumb"]),

    fetchProgramDetails() {
      return axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/getProgramGenericInfo",
          {
            program_id: this.urlProgram.id,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.allProgramsSettings = response.data.programInfo;
        });
    },
    updateBc() {
      if (this.urlCategory.id) {
        const crumb = [
          {
            routeName: "Home",
            text: '<i class="fa-solid fa-house-chimney"></i>',
          },
          {
            routeName: "Shop",
            text: "Shop",
          },
          {
            route: this.urlCategory.slug,
            text: this.urlCategory.title,
          },
        ];
        this.updateDynamicBreadcrumb(crumb);
      }
    },
    categoryDetails() {
      return axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/getCategoryGenericInfo",
          {
            slug: this.urlCategory.slug,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.success !== undefined) {
            this.urlCategory.id = response.data.categoryInfo.id;
            this.urlCategory.title = response.data.categoryInfo.title;
            this.urlCategory.desc = response.data.categoryInfo.description;
            this.urlCategory.name = response.data.categoryInfo.name;
            this.urlCategory.meta_title = response.data.categoryInfo.meta_title;
            this.urlCategory.meta_description = response.data.categoryInfo.meta_description;
            this.urlCategory.meta_keywords = response.data.categoryInfo.meta_keywords;
            this.urlCategory.slug = response.data.categoryInfo.seo_url;
            this.urlCategory.display_cutoff = response.data.categoryInfo.display_cutoff;
          }
        });
    },
    cartExpired() {
      this.fetchProducts();
    },
    expand(accordionIndex) {
      if (this.currentAccordion == accordionIndex) this.currentAccordion = "";
      else this.currentAccordion = accordionIndex;
    },

    getPerPage() {
      let val;
      if (localStorage.getItem("perPage"))
        val = localStorage.getItem("perPage");
      if (val) {
        return val;
      }
      let all_site_settings = JSON.parse(localStorage.getItem("site_settings"));
      val = all_site_settings.default_products_per_page
        ? all_site_settings.default_products_per_page
        : 100;
      return parseInt(val, 10);
    },

    getBreakdown(breakdownJson, box_in_unit) {
      let breakdowns = JSON.parse(breakdownJson);
      let str = "";
      let unit_for_bd = "";
      if (breakdowns.length > 0) {
        str =
          '<table class="tbl_breakdown"><thead><tr><td colspan=3 align="center">Breakdown – Subject to Change</td></tr><thead><tbody><tr class="popper-content-header"><td align="left">Product</td><td align="right">Units</td></tr>';
        breakdowns.forEach((breakdown) => {
          unit_for_bd = breakdown.bunches * breakdown.stemsBunch;
          if (breakdown.stemsBunch > 1) box_in_unit = "stem";
          else box_in_unit = "bunch";

          str =
            str +
            '<tr><td align="left">' +
            breakdown.productDescription +
            "</td>" +
            '<td align="right">' +
            unit_for_bd +
            " " +
            box_in_unit +
            "</td></tr>";
        });

        str = str + "</tbody></table>";
      }
      return str;
    },

    getBreakdownForPulls(breakdownJson, box_in_unit) {
      let breakdowns = JSON.parse(breakdownJson);
      let str = "";
      let unit_for_bd = "";
      if (breakdowns.length > 0) {
        str =
          '<table class="tbl_breakdown"><thead><tr><td colspan=3 align="center">Breakdown – Subject to Change</td></tr><thead><tbody><tr class="popper-content-header"><td align="left">Product</td><td align="right">Units</td></tr>';
        breakdowns.forEach((breakdown) => {
          unit_for_bd = breakdown.bunches;
          if (breakdown.stemsBunch == "bunch") {
            if (breakdown.bunches > 1) box_in_unit = "bunches";
            else box_in_unit = "bunch";
          } else if (breakdown.stemsBunch == "stem") {
            if (breakdown.bunches > 1) box_in_unit = "stems";
            else box_in_unit = "stem";
          }

          str =
            str +
            '<tr><td align="left">' +
            breakdown.productDescription +
            "</td>" +
            '<td align="right">' +
            unit_for_bd +
            " " +
            box_in_unit +
            "</td></tr>";
        });

        str = str + "</tbody></table>";
      }
      return str;
    },

    getSortBy() {
      if (localStorage.getItem("sortBy")) return localStorage.getItem("sortBy");
      else return "Name-ASC";
    },

    changePage(pageNumb) {
      this.filters.pageNumb = pageNumb;
    },
    setFilterCriteria(opt, evt = "") {
      if (!(opt.group in this.filters.criteria)) {
        this.filters.criteria[opt.group] = {};
      }

      let isChecked = true;
      if (evt === "") {
        isChecked = !(opt.value in this.filters.criteria[opt.group]);
      } else {
        isChecked = evt.target.checked;
      }

      if (isChecked) {
        this.filters.criteria[opt.group][opt.value] = opt.label;
      } else {
        this.removeFilterCriteria(opt.group, opt.value);
      }
    },
    removeFilterCriteria(group, key) {
      delete this.filters.criteria[group][key];
    },
    resetFilters() {
      this.triggerWatch = false;
      this.filters.perPage = 100;
      this.filters.sortBy = "Name-ASC";
      this.filters.search = "";
      let newFilterCriteria = {};
      if ("filter_program" in this.$route.query && "filter_bt" in this.$route.query && this.$route.query.filter_bt === "1") {
        newFilterCriteria = {'filter_program': this.filters.criteria.filter_program };
      }
      this.$nextTick(() => {
        this.filters.criteria = newFilterCriteria;
        this.triggerWatch = true;
      });
    },
    fetchProducts() {
      this.isLoading = true;

      let apiUrl = process.env.VUE_APP_API_BASE + "auth/";
      if (this.isLoggedIn) {
        apiUrl += "getCategoryInventory";
      } else {
        apiUrl += "allCategoryInventory";
        this.filters.sortBy = this.filters.sortBy.replace("Price","Name");
        localStorage.setItem("sortBy", this.filters.sortBy);
      }
      axios
        .post(
          apiUrl,
          {
            slug: this.urlCategory.slug,
            filters: this.apiBody,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.prods.data = response.data.products;
          if (response.data.products.length > 0) {
            this.prods.total = this.prods.data[0]["recordCount"];
          } else {
            this.prods.total = 0;
          }

          let query = {
            ...this.apiBody,
          };
          query.criteria = JSON.stringify(query.criteria);
          query.criteriaInt = JSON.stringify(query.criteriaInt);
          
          query.s_search = query.search;
          query.search = "";

          // if (this.urlCategory.id) {
          //   query.filter_program = this.urlCategory.id;
          //   query.filter_bt = 1;
          // }

          this.$router.replace({
            path: `/${this.urlCategory.slug}`,
            query: { ...query },
          });

          setTimeout(() => {
            this.viewProductListToGA()  
          }, 1000);
          this.isLoading = false;
        });
    },
    fetchProductsTypes() {
      axios
        .get(
          process.env.VUE_APP_API_BASE + "auth/getProductTypes",
          {}, // the data to post
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.productTypes = response.data.types;
          localStorage.setItem(
            "productTypes",
            JSON.stringify(response.data.types)
          );
        });
    },

    fetchDates() {
      if (this.isLoggedIn && this.urlCategory.slug != '') {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/getCategoryDates",
            {
              slug: this.urlCategory.slug,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((data) => {
            const cutOffs = {};
            let isDefaultSet = false;
            if (data.data.error == false) {
              data.data.disabled_dates.forEach((disabledDate) => {
                const formatted = this.appendTimeForBrowser(disabledDate);
                this.disabledDates.push({
                  start: formatted,
                  end: formatted,
                  date: disabledDate,
                });
              });
              this.minDate = this.appendTimeForBrowser(data.data.min_date);
              this.maxDate = this.appendTimeForBrowser(data.data.max_date);
              data.data.dates.forEach((availDate) => {
                this.prepareCutoffObj(cutOffs, availDate);

                if (!isDefaultSet && this.filters.date && availDate["delivery_date"] === this.filters.date) {
                  isDefaultSet = true;
                }
              });
            } else {
              const errMsg = "Currently there are no delivery dates available in your area to deliver your request. To ensure you get the freshest product, please check back later to place your order.";
              this.triggerFlashAlert(errMsg, "error");
              this.displayProducts = false;
            } 
            
            if (data.data.dates) {
              this.availableDates = data.data.dates;
            }
            this.cutOffs = cutOffs;

            if (!this.availableDates.length) {
              const errMsg = "Currently there are no delivery dates available in your area to deliver your request. To ensure you get the freshest product, please check back later to place your order.";
              this.triggerFlashAlert(errMsg, "error");
              this.displayProducts = false;
            } else {
              if (!isDefaultSet) {
                if (this.getShopDeliveryDate) {
                  window.location = this.urlCategory.slug;
                } else {
                  this.filters.tmpDate = this.availableDates[0]['delivery_date'];
                  this.processDateChange();
                }
              } else {
                this.fetchFilters();
              }
            }
          });
      }
    },

    fetchFilters() {
      let apiUrl = "";
      if (this.isLoggedIn)
        apiUrl = process.env.VUE_APP_API_BASE + "auth/getCategoryFilters";
      else apiUrl = process.env.VUE_APP_API_BASE + "auth/allCategoryFilters";
      axios
        .post(
          apiUrl,
          {
            slug: this.urlCategory.slug,
            filters: this.apiBody,
            program_filter: 0,
          }, // the data to post
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((data) => {
          this.filterSection = data.data.filters;

          const criteriaNew = {};
          for (const key in this.filters.criteria) {
            for (const key2 in this.filterSection) {
              if (key in this.filterSection[key2]) {
                criteriaNew[key] = {};
                for (const key3 in this.filters.criteria[key]) {
                  criteriaNew[key][key3] = this.filters.criteria[key][key3];
                }
                break;
              }
            }
          }

          const readFilterFromUrl = (key, group) => {
            if (key in this.$route.query) {
              const id = this.$route.query[key];
              let node = this.filterSection[group][key];
              node = _.find(node, ["id", parseInt(id, 10)]);
              if (node) {
                if (!(key in criteriaNew)) {
                  criteriaNew[key] = {};
                }
                criteriaNew[key][id] = node.label;
              }
            }
          };
          readFilterFromUrl("filter_color_combo", "colors");
          readFilterFromUrl("filter_program", "programs");
          readFilterFromUrl("filter_category", "categories");
          readFilterFromUrl("filter_maincategory", "maincategories");

          this.filters.criteria = criteriaNew;

          let availablePrograms = [];
          this.filterSection.programs.filter_program.forEach((program) => {
            availablePrograms.push(program.id);
          });
          // if (this.urlCategory.id) {
          //   if (availablePrograms.includes(this.urlCategory.id) && this.urlCategory.display_cutoff) {
          //     this.displayCutoffForPrograms.push(this.urlCategory.id);
          //   }
          // }
          this.allProgramsSettings.forEach((programSetting) => {
            if (
              availablePrograms.includes(programSetting.id) &&
              programSetting.display_cutoff
            ) {
              this.displayCutoffForPrograms.push(programSetting.id);
            }
          });

          this.fetchDisclaimers(availablePrograms);
        });
    },

    fetchDisclaimers(availablePrograms) {
      let apiUrl = "";
      if (this.isLoggedIn) {
        apiUrl = process.env.VUE_APP_API_BASE + "auth/getProgramDisclaimers";
        axios
          .post(
            apiUrl,
            {
              page: "SHP",
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((data) => {
            this.disclaimers = [];
            let allDisclaimers = data.data.disclaimers;
            allDisclaimers.forEach((disclaimer) => {
              if (availablePrograms.includes(disclaimer.program_id)) {
                this.disclaimers.push(disclaimer)
              }
            })
          });
      }
    },

    changeView(viewType) {
      this.view = viewType;
      localStorage.setItem("viewType", this.view);
    },

    addToWlAfterMath(addStatus) {
      let flashMsg = "";
      if (!addStatus) {
        flashMsg = "You must be logged in!";
      } else {
        flashMsg = "Successfully added to the WL!";
      }

      this.triggerFlashAlert(flashMsg);
    },

    addToCartAfterMath(response) {
      if (response.level == "success") {
        this.fetchProducts();
        this.triggerFlashAlert(response.msg, response.level);
      } else {
        this.triggerFlashAlert(response.msg, response.level);
      }
    },

    processDateChange() {
      this.filters.date = this.formatDateWithZeroFromStr(this.appendTime(this.filters.tmpDate));

      if (this.triggerWatch) {
        this.clearCartComments();
        this.fetchFilters();
        this.fetchProducts();
      }
      this.updateDeliveryDateLabel();
    },

    applySearch() {
      this.filters.pageNumb = 1;
      this.fetchProducts();
    },

    updateDeliveryDateLabel() {
      let apiUrl = process.env.VUE_APP_API_BASE + "auth/getDeliveryDateLabel";
      if (this.isLoggedIn && this.urlCategory.id > 0 && 0 > 1) {
        axios
        .post(
          apiUrl,
          {
            program_id: this.urlCategory.id,
            delivery_date: this.filters.tmpDate,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((data) => {
          if (data) {
            this.deliveryDateLabel = data.data.deliveryDateLabel;
            this.deliveryDateDescription = data.data.deliveryDateDescription;
          }
        });
      } else {
        this.deliveryDateLabel = 'SELECT DELIVERY DATE';
        this.deliveryDateDescription = '';
      }
    },

    viewProductListToGA() {
      let products = this.prods.data
      
        const items = [];
        products.forEach((prod) => {
          items.push({
            item_id: prod.product_id,
            item_name: prod.name,
            affiliation: prod.farm_name,
            item_list_id: prod.programtypeId,
            item_list_name: prod.programtypename,
            item_category: prod.category_name,
            item_category2: prod.variety_name,
            item_variant: prod.color_name,
            item_category3: prod.grade_name,
            quantity: parseInt(prod.qty),
            price: parseFloat(prod.price_per_unit),
            price_after_coupon: prod.price_per_unit > 0 ? parseFloat(prod.price_per_unit) : parseFloat(prod.price_per_unit),
            currency: "USD",
          });
        });
      
        let productObj = {
          item_list_id: this.urlCategory.id == 0 ? '' : this.urlCategory.id,
          item_list_name: this.urlCategory.title != '' ? this.urlCategory.title : '',
          items: items
        };
        this.$gtag.event("view_item_list", productObj);
    },
  },
  watch: {
    // $route() {
    //   this.updateBc();
    // },
    "filters.perPage": function () {
      if (this.triggerWatch) {
        this.filters.pageNumb = 1;
        localStorage.setItem("perPage", this.filters.perPage);
        this.fetchProducts();
      }
    },
    "filters.sortBy": function () {
      if (this.triggerWatch) {
        this.filters.pageNumb = 1;
        localStorage.setItem("sortBy", this.filters.sortBy);
        this.fetchProducts();
      }
    },
    "filters.pageNumb": function () {
      if (this.triggerWatch) {
        this.fetchProducts();
      }
    },
    "filters.criteria": {
      handler() {
        if (this.triggerWatch) {
          if (this.pageLoaded) this.filters.pageNumb = 1;
          this.fetchProducts();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.font-weight-bold {
  font-weight: bold;
}
.input-group-append {
  right: 0px;
}
.col.product-name.pl-0 div a {
  text-decoration: none;
}
.filters-selected {
  color: #000;
  padding: 5px 8px;
  margin-right: 10px;
  margin-bottom: 10px;
  float: left;
  background: #f2f2f2;
  font-weight: normal;
}
.filters-selected a {
  text-decoration: none;
}

.form-select {
  background-color: #fff !important;
}
select.decorated option:hover {
  box-shadow: 0 0 10px 100px #1882a8 inset;
  background: #ccc;
}
li.bubble_color {
  float: left;
  padding: 5px !important;
}
#grid-list-btn-container {
  text-align: right;
}
#selected-filters-list-top {
  padding: 10px 10px 10px 0px !important;
}

#selected-filters-list-top span {
  color: #000;
  padding: 10px 15px;
  margin-right: 10px;
  float: left;
  background: #f2f2f2;
  font-weight: normal;
  font-size: 12px;
}
#view-grid .product-wrapper {
  min-height: 250px !important;
  max-height: 500px;
}
.mt-10 {
  margin-top: 10px;
}

.bubble_color_wrapper {
  text-align: center;
  display: inline-block;
}

.bolder-size {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
}
.calender_container {
  margin-left: 0px;
}

.star-color {
  color: #f37f62;
}

.tbl_breakdown,
.tbl_breakdown tr {
  border: 1px solid #fff;
}

.tbl_breakdown {
  width: 350px;
}
.no-overflow {
  overflow: initial;
}

.filter-image.filter-color {
  position: relative;
}

.action div { width:100%}
.action :deep(.cutoff_label) {margin: 0 auto !important}
@media (min-width: 992px) {
  .bolder-size {
    width: calc(25% + 50px);
  }
}
@media screen and (max-width: 767px) {
  .product-card .action :deep(.btn-theme) {
    margin-top: 4px !important;
  }
}
</style>
